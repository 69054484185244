<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden px-2">
          <div class="bg-soft p-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <div class="text-white">
                  <h2 class="text-white">{{ nameApp }}</h2>
                  <h6 class="text-white">Iniciar Sesión.</h6>
                </div>
              </div>
              <div class="col-5 align-self-end mb-3">
                <img :src="logoApp" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{
              authError
            }}</b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="formSubmit">
              <b-form-group
                class="mb-3"
                id="input-group-1"
                label="Correo electrónico"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="text"
                  placeholder="Ingresa el Correo electrónico"
                  :class="{ 'is-invalid': $v.form.email.$error }"
                ></b-form-input>
                <div v-if="$v.form.email.$error" class="invalid-feedback">
                  <span v-if="!$v.form.email.required">Correo electrónico es requerido.</span>
                  <span v-if="!$v.form.email.email"
                    >Ingresa un correo electrónico valido.</span
                  >
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="input-group-2"
                label="Contraseña"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  type="password"
                  placeholder="Ingresa la Contraseña"
                  :class="{
                    'is-invalid': $v.form.password.$error
                  }"
                ></b-form-input>
                <div v-if="!$v.form.password.required" class="invalid-feedback">
                  Contraseña es requerida.
                </div>
              </b-form-group>
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="outline-dark" class="btn-block"
                  >Iniciar Sesión</b-button
                >
              </div>
              <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">Iniciar Sesión con:</h5>

                <ul class="list-inline">
                  <li class="list-inline-item" @click="loginWithGoogle">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item bg-danger text-white border-danger"
                    >
                      <i class="mdi mdi-google"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock me-1"></i> ¿Olvidaste tu contraseña?
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            ¿Aun no tienes una cuenta?
            <router-link tag="a" to="/register" class="fw-medium text-primary"
              >Regístrate</router-link
            >
          </p>
          <Footer></Footer>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
// import axios from 'axios'
import Layout from '../../layouts/auth'
import { authMethods2, notificationMethods } from '@/state/helpers'
import { validationMixin } from 'vuelidate'
const { required, minLength, email } = require('vuelidate/lib/validators')
import { monitoringUrl, logoApp, nameApp, countryApp } from '@/constants/config'
import { mapState, mapGetters, mapActions } from 'vuex'
import { scopeGoogle, googleAuthUrl } from '@/constants/google'
import appConfig from '@/app.config'
import Footer from './components/footer.vue'

/**
 * Login component
 */
export default {
  page: {
    title: 'Iniciar sesión',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    Footer
  },
  data() {
    return {
      logoApp,
      nameApp,
      countryApp,
      form: {
        email: '',
        password: ''
      },
      paramsLoginGoogle: {
        response_type: 'code',
        client_id: process.env.VUE_APP_GOOGLE_ID,
        redirect_uri: `${process.env.VUE_APP_FRONT_URL}/user/auth/login/google`,
        prompt: 'select_account',
        access_type: 'offline',
        scope: scopeGoogle
      },
      authError: null,
      tryingToLogIn: false,
      isAuthError: false
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(4)
      },
      email: {
        required,
        email,
        minLength: minLength(4)
      }
    }
  },
  computed: {
    ...mapState('authfack', ['status']),
    ...mapGetters({
      currentUser: 'user/currentUser',
      processing: 'user/processing',
      loginError: 'user/loginError'
    }),
    notification() {
      return this.$store ? this.$store.state.notification : null
    }
  },
  methods: {
    ...authMethods2,
    ...notificationMethods,
    ...mapActions({ login: 'user/login' }),
    loginWithGoogle() {
      const urlParams = new URLSearchParams(this.paramsLoginGoogle).toString()
      window.location = `${googleAuthUrl}?${urlParams}`
    },
    formSubmit() {
      this.submitted = true
      this.$v.$touch()
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        this.login({
          email: this.form.email,
          password: this.form.password
        })
      }
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(val) {
        if (val && val.email) {
          setTimeout(() => {
            this.$router.push(monitoringUrl)
          }, 200)
        }
      }
    },
    loginError: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          console.log(val)
        }
      }
    }
  },
  mounted() {
    console.log(this.countryApp)
  }
}
</script>

<style lang="scss" module></style>
